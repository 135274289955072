@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';

@primary-color: #2872AF;
@success-color: #0F7F12;
@error-color: #C80813;
@warning-color: #faad14;
@gray-color: #D9D9D9;
@secondary-background: #2C3338;
@layout-header-height: 48px;
@layout-header-padding: 0 16px 0 0;
@layout-header-background: @secondary-background;

@btn-default-color: @primary-color;
@btn-default-border: @primary-color;

@table-row-hover-bg: #DEE2E6;
@table-padding-vertical: 8px;
@table-padding-horizontal: 8px;
@font-size-base: 14px;

@card-head-background: #f5f6f7;
@input-disabled-color: rgba(0, 0, 0, 0.45);

.bg-light {
    background-color: #f0f2f5;
}

.bg-gray {
    background-color: @gray-color;
}

.text-primary {
    color: @primary-color;
}

.text-success {
    color: @success-color;
}

.text-error {
    color: @error-color;
}

.text-warning {
    color: @warning-color;
}

.text-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

// ICON
.anticon {
    vertical-align: 0.125rem;
}

// BUTTON
.ant-btn.ant-btn-default:hover,
.ant-btn.ant-btn-default:focus {
    color: rgba(@primary-color, .7);
    border-color: rgba(@primary-color, .7);
}

.ant-btn.ant-btn-primary.bg-secondary,
.ant-btn.ant-btn-primary.bg-secondary:hover,
.ant-btn.ant-btn-primary.bg-secondary:focus,
.ant-btn.ant-btn-primary.bg-secondary:active {
    border-color: @primary-color;
    background: @primary-color;
}

.custom-btn.ant-btn-primary {
    width: 120px;
    font-weight: bold;

    &.ant-btn-success {
        background-color: @success-color;
        border-color: @success-color;

        &:hover {
            background-color: @success-color-hover;
            border-color: @success-color-hover;
        }
    }
}

.custom-btn.ant-btn-default {
    width: 120px;
    font-weight: bold;

    &.ant-btn-success {
        border-color: @success-color;
        color: @success-color;

        &:hover {
            border-color: @success-color-hover;
            color: @success-color-hover;
        }
    }

    &.ant-btn-dangerous {
        border-color: @error-color;
        color: @error-color;

        &:hover {
            border-color: @error-color-hover;
            color: @error-color-hover;
        }
    }
}

.custom-btn-tab {
    border-color: @gray-color;
    color: #000000d9;

    &.active {
        border-color: @primary-color;
        color: @primary-color;
        font-weight: bold;
        cursor: default;
    }
}

// INPUT
.ant-input-search.ant-input-search-with-button {
    .ant-input-group-addon {
        .ant-btn-primary.ant-input-search-button {
            background-color: @white;
            color: @primary-color;
            border-color: @primary-color;
        }
        .ant-btn-primary.ant-input-search-button:hover {
            color: rgba(@primary-color, .7);
            border-color: rgba(@primary-color, .7);
        }
    }
}

.ant-table {
    .ant-table-thead {
        .ant-table-cell {
            text-align: center !important;
        }
    }
    .ant-input-number-input {
        text-align: right;
    }
}

.table-bg-blue {
    background-color: rgb(239 246 255);

    &:hover > td {
        background-color: rgb(191 219 254) !important;
    }
}

.table-bg-orange {
    background-color: rgb(255 247 237);

    &:hover > td {
        background-color: rgb(254 215 170) !important;
    }
}

.table-bg-green {
    background-color: rgb(240 253 244);

    &:hover > td {
        background-color: rgb(187 247 208) !important;
    }
}

// HEADER
.ant-layout-header {
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
        background-color: #f0f2f5;

        .anticon,
        .ant-menu-title-content a {
            color: @primary-color;
        }
    }

    .anticon,
    .ant-menu-title-content a {
        font-weight: bold;
        color: @white;
    }

    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item > a,
    .ant-menu-dark .ant-menu-item > span > a {
        color: @white;
    }
}

.button-gray, .button-gray:focus, .button-gray:active {
    background-color: #e9ecef !important;
    border-color: #e9ecef !important;
    color: rgb(107 114 128) !important;

    &:hover {
        background-color: #dee2e6 !important;
        border-color: #dee2e6 !important;
        color: rgb(107 114 128) !important;
    }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    vertical-align: 1px;
}

.ant-collapse.header-align-center {
    .ant-collapse-header {
        align-items: center !important;
    }
}

.collapse-no-padding-collapse {
    .collapse-no-padding-panel {
        .ant-collapse-content-box {
            padding: 0;
        }
    }
}

// CARD
.card-description {
    border-left: 5px solid @primary-color;
}

.ant-card {
    &.ant-card-bordered {
        border: 1px solid #dddfe2;
    }
}

.card-table-header {
    border: 1px solid #f0f0f0 !important;

    .ant-card-body {
        padding: 0.5rem 0.75rem;
    }
}

// RADIO
.custom-radio {
    .custom-radio-item {
        width: 150px;
        color: #858685;
        background-color:@gray-color;
        font-weight: bold;

        &.ant-radio-button-wrapper-checked {
            &.item-success {
                background: @success-color;
                border-color: @success-color;
            }

            &.item-error {
                background: @error-color;
                border-color: @error-color;
            }
        }
    }
}

// PICKER RANGE
.ant-picker-range {
    .ant-picker-active-bar {
        bottom: 0;
    }
}

// EDIT TABLE
.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
    width: 100%;
}

.label-input {
    background-color: @white;
    border: 1px solid @gray-color;
    border-radius: 2px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
}